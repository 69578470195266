<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('sideBar.settingsManagement')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :disabled="!isSuperAdmin()"
                    :rules="[requiredValue]"
                    v-model="exchangeRate"
                    :label="$t('settings.exchangeRate')"
                    class="required"
                    type="number"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                v-if="isSuperAdmin()"
                :loading="isBtnLoading"
                small
                color="primary darken-1"
                class="pull-right"
                @click="onSaveExchangeRate"
              >{{ $t("common.save") }}
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import functionUtils from 'utils/functionUtils'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
export default {
  data: () => ({
    valid: false,
    exchangeRate: null,
    isBtnLoading: false,
    isLoading: false
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'GET_CURRENT_ACCOUNT'])
  },
  created () {
    this.isLoading = true
    this.GET_EXCHANGE_RATE()
      .then(
        function (res) {
          let data = res.data
          this.exchangeRate = data.exchange_rate
          this.isLoading = false
        }.bind(this)
      )
      .catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
  },
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (!functionUtils.isNull(value) && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Save exchange rate
     */
    onSaveExchangeRate: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          exchange_rate: this.exchangeRate
        }
        this.isBtnLoading = true
        this.SAVE_EXCHANGE_RATE(filter)
          .then(
            function () {
              this.isBtnLoading = false
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
            }.bind(this)
          )
          .catch(
            function () {
              this.isBtnLoading = false
              this.ON_SHOW_TOAST({
                message: this.$t('common.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }.bind(this)
          )
      }
    },
    ...mapActions(['SAVE_EXCHANGE_RATE', 'ON_SHOW_TOAST', 'GET_EXCHANGE_RATE'])
  }
}
</script>

<style lang="scss" scoped>
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
